import React from "react"
import Prose from "../../components/prose"

export default () => (
      <Prose>
        <h1>third post</h1>
        <h2>its the third post post</h2>
        <h3> its number three</h3>
        <p>its selected</p>
      </Prose>
)
